<template>
  <div>
    <el-row
      type="flex"
      class="row-bg"
      justify="space-between"
      align="center">
      <el-col :span="12">
        <div class="title">
          <h1>
            Редактирование новости
          </h1>
        </div>
      </el-col>
    </el-row>
    <news-form
      :initial-form-values="formData"
      :initial-editor-values="editorData"
      btn-text="Сохранить"
      @submit="saveNews" />
  </div>
</template>
<script>

import NewsForm from '@/components/news/newsForm.vue';

export default {
  name: 'EditingNews',
  components: {
    NewsForm,
  },
  data() {
    return {
      formData: {
        title: 'Заголовок',
        date: Date.now(),
        time: new Date().toLocaleTimeString(),
        textAnnonce: 'текст текст текст текст текст текст текст текст текст текст текст текст текст текст ',
        image: '',
      },
      editorData: {
        blocks: [
          {
            type: 'Slider',
            data: [
              {
                id: '0',
                url: 'https://wallscloud.net/uploads/cache/2845750753/yosemite-YvNp-1024x576-MM-90.jpg',
              },
              {
                id: '1',
                url: 'https://st4.depositphotos.com/27745818/31076/i/1600/depositphotos_310761828-stock-photo-shot-of-the-beautiful-tour.jpg',
              },
            ],
          },
          {
            type: 'Quote',
            data: {
              title: 'Лоран Дарту',
              author: 'Президент и главный исполнительный директор Bridgestone EMIA',
              text: 'Мы в Bridgestone рассматриваем электрические автомобили как отличную',
            },
          },
        ],
      },
    };
  },
  methods: {
    saveNews() {},
  },
};
</script>
<style lang="scss">
</style>
